<template>
  <v-container>
    <go-back-btn :routeToGo="routeToGo" />
    <page-header class="mb-3" :title="title" />
    <v-data-table
      :headers="headers"
      :items="licenciasClientes"
      :single-expand="singleExpand"
      :search="search"
      :footer-props="{ 'items-per-page-text': 'Licencias por página' }"
      :expanded.sync="expanded"
      item-key="clienteId"
      show-expand
      class="elevation-1"
      :loading="isLoading"
      :custom-sort="customSort"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar cliente"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn @click="loadLicenciasClientes" icon small align="end">
                <v-icon> mdi-reload </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="3" class="pt-5 text-right">
              <v-btn
                color="primary"
                @click="openModalFechasCorte()"
                class="text-right mr-2"
              >
                Fechas de corte
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          v-if="item.datosComplementarios && item.mostrarScript"
        >
          Ultimo script: {{ item.datosComplementarios.script }}. <br />Fecha de
          alta: {{ item.datosComplementarios.fechaAlta }}. Fecha de ejecución:
          {{ item.datosComplementarios.fechaEjecucion }}. Resultado de
          ejecución: {{ item.datosComplementarios.resultadoEjecucion }}. Mensaje
          de ejecución: {{ item.datosComplementarios.mensajeEjecucion }}.
        </td>
        <td v-else :colspan="headers.length">
          No hay script de {{ item.nombre }}.
        </td>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              size="20"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar nueva fecha corte</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              size="20"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Eliminar nueva fecha corte</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="loadLicenciasClientes"> Resetear </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="40%" @keydown.esc="close()">
      <v-card outlined>
        <v-card-title>
          <span class="py-1 primary--text">{{ formEditTitle }}</span>
        </v-card-title>
        <v-card-text class="pb-2">
          <v-form
            v-model="isFormValid"
            ref="form"
            form="form"
            id="form"
            @submit.prevent="save()"
          >
            <v-row class="d-flex justify-center pb-0">
              <v-col cols="6" class="pb-0">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="auto"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      label="Fecha nuevo corte"
                      ref="dateFromatted"
                      dense
                      outlined
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-mask="'##/##/####'"
                      hint="Formato DD/MM/AAAA"
                      :rules="
                        dateFormatted
                          ? [rules.required, rules.validDate]
                          : [rules.required]
                      "
                      @blur="date = parseDate(dateFormatted)"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    class="d-flex justify-center mb-1"
                    color="blue darken-1"
                    no-title
                    locale="es-ar"
                    @change="dateFormatted = formatDate(date)"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pb-0">
                <v-text-field
                  outlined
                  dense
                  v-model="time"
                  v-mask="'##:##'"
                  ref="time"
                  label="Hora"
                  append-icon="mdi-clock-time-four-outline"
                  :rules="
                    time ? [rules.required, rules.validTime] : [rules.required]
                  "
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="close">
            <span align="center"> Cancelar </span>
          </v-btn>
          <v-btn
            color="#195472"
            type="submit"
            :disabled="!isFormValid"
            form="form"
          >
            <span class="myLabel" align="center">Guardar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="30%"
      @keydown.esc="dialogDelete = false"
    >
      <v-card>
        <v-card-title class="primary--text d-flex mb-1"
          >¿Eliminar nueva fecha corte?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-left" text @click="closeDelete">Cancelar</v-btn>
          <v-btn text color="primary" dark @click="deleteItemConfirm"
            >Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
//import enumsAdmin from "@/utils/enums/modules/index.js";
import { mask } from "vue-the-mask";

export default {
  components: { PageHeader, GoBackBtn },
  directives: { mask },
  name: "TableClientes",

  data() {
    return {
      isLoading: false,
      dialog: false,
      expanded: [],
      time: null,
      isFormValid: false,
      rules: {
        validDate: (value) => {
          // formato DD/MM/YYYY
          const pattern =
            /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})$/;
          return pattern.test(value) || "Formato de fecha no válido";
        },
        required: (value) => !!value || "Campo requerido",
        validTime: (value) => {
          if (!value) return true;
          // formato HH/mm
          const horasMinutos = value?.split(":");
          return (
            (horasMinutos[0] < 24 &&
              horasMinutos[1] < 60 &&
              horasMinutos[1].length == 2) ||
            "Formato de hora no válido"
          );
        },
      },
      licenciasClientes: [],
      formEditTitle: "Editar nueva fecha corte",
      search: "",
      dialogDelete: false,
      routeToGo: "/",
      singleExpand: true,
      // optionCode: enums.webSiteOptions.FECHA_CORTE,
      date: null,
      dateFormatted: null,
      menu: false,
      title: "Licencias de clientes",
      headers: [
        {
          text: "Nombre cliente",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Fecha corte", value: "fechaCorte", sortable: true },
        { text: "Ultima conexión", value: "ultimaConexion", sortable: true },
        { text: "Nueva fecha corte", value: "nuevaFechaCorte", sortable: true },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      clienteId: null,
      // allowedActions: null,
      canFechaCorte: false,
    };
  },
  computed: {
    ...mapGetters({
      alert: "alert",
    }),
  },
  created() {
    this.loadLicenciasClientes();
    // this.setPermisos();
  },

  methods: {
    ...mapActions({
      getLicenciasClientes: "clientesData/getClientesAxios",
      postNuevaFechaCorte: "clientesData/postNuevaFechaCorte",
      deleteNuevaFechaCorte: "clientesData/deleteNuevaFechaCorte",
      setAlert: "setAlert",
    }),
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        switch (index[0]) {
          case "fechaCorte":
            if (!isDesc[0]) {
              return (
                new Date(this.parseDate(b[index])) -
                new Date(this.parseDate(a[index]))
              );
            } else {
              return (
                new Date(this.parseDate(a[index])) -
                new Date(this.parseDate(b[index]))
              );
            }
          case "nuevaFechaCorte":
            if (!isDesc[0]) {
              return (
                new Date(this.parseDate(b[index])) -
                new Date(this.parseDate(a[index]))
              );
            } else {
              return (
                new Date(this.parseDate(a[index])) -
                new Date(this.parseDate(b[index]))
              );
            }
          case "ultimaConexion":
            if (!isDesc[0]) {
              return (
                new Date(this.parseDate(b[index])) -
                new Date(this.parseDate(a[index]))
              );
            } else {
              return (
                new Date(this.parseDate(a[index])) -
                new Date(this.parseDate(b[index]))
              );
            }
          default: {
            if (typeof a[index] !== "undefined") {
              if (!isDesc[0]) {
                return a[index]
                  .toLowerCase()
                  .localeCompare(b[index].toLowerCase());
              } else {
                return b[index]
                  .toLowerCase()
                  .localeCompare(a[index].toLowerCase());
              }
            }
          }
        }
      });
      return items;
    },
    openModalFechasCorte() {
      this.$router.push({
        name: "FechasCorte",
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [month, day, year] = date.split("/");
        return `${year}-${day.padStart(2, "0")}-${month.padStart(2, "0")}`;
      }
    },
    async loadLicenciasClientes() {
      this.isLoading = true;
      const responseaxios = await this.getLicenciasClientes();
      this.licenciasClientes = responseaxios;
      this.isLoading = false;
    },

    editItem(item) {
      this.clienteId = item.clienteId;
      this.dateFormatted = item.nuevaFechaCorte;
      this.date = this.parseDate(item.nuevaFechaCorte);
      this.time =
        item.mostrarScript && item.datosComplementarios && item.nuevaFechaCorte
          ? item.datosComplementarios.horaFechaCorte
          : null;
      this.dialog = true;
    },

    deleteItem(item) {
      this.clienteId = item.clienteId;
      this.dateFormatted = item.nuevaFechaCorte;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.deleteNuevaFechaCorte(this.clienteId);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$refs["dateFromatted"].reset();
      this.$refs["time"].reset();
      this.loadLicenciasClientes();
    },
    closeDelete() {
      this.dialogDelete = false;
      setTimeout(() => {
        this.loadLicenciasClientes();
      }, 500);
    },
    async save() {
      const tiempo = !this.time ? "00:00" : this.time.toString();
      let payload = {
        fechaCorte: `${this.date}T${tiempo}:00.732Z`,
        clienteId: this.clienteId,
      };
      const res = await this.postNuevaFechaCorte(payload);
      if (res.status == 200) {
        this.setAlert({ type: "success", message: "Actualizado con éxito" });
      }
      this.close();
    },
  },
};
</script>
<style scoped>
::v-deep .myLabel {
  color: white;
}
</style>
